import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import BG from '../images/flex-log-grey.jpg'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #232323;
  min-height: 100vh;
  max-width: 100vw;
  flex-direction: column;
  align-items: center;
`

const Image = styled.img`
  height: 50vw;
  margin: auto;
`
const Text = styled.h3`
  font-size: 3vw;
  color: #fff;
  font-weight: bold;
  text-align: center;
  position: absolute;
  margin-top: 10%;
`

const NotFoundPage = () => (
  <Layout>
    <Wrapper>
      <Text>Hey, looks like this page doesn't exist....</Text>
      <Image src={BG} />
    </Wrapper>
  </Layout>
)

export default NotFoundPage
